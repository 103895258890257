import React, { Fragment, lazy, Suspense } from 'react';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';

import AuthGuard from 'src/components/AuthGuard/AuthGuard';
import AuthGuardWithCallback, { AuthGuardWithCallbackProps } from 'src/components/AuthGuard/AuthGuardWithCallback';
import GuestGuard from 'src/components/GuestGuard/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout/DashboardLayout';
import { RETRIEVE_UPDATE_CONTRACTOR_CONFIGURATION_PERMISSIONS } from 'src/views/shared/legalEntity/permissions';

import BaseAuthGuard from './components/AuthGuard/BaseAuthGuard';
import { authGuardWithPermissions, Permission } from './services/auth/permissions';
import { Role } from './types/legalEntity';
import { stringifyParams } from './utils/routes';
import { AdditionalTextCategory, CurrentUser } from './types';

interface RouteBase {
  exact?: boolean;
  getLinkPath?: (params?: unknown) => string;
  guard?: any;
  layout?: any;
  params?: string[];
  path?: string;
  index?: boolean;
  routes?: Routes;
}

// We can optionally specify routes for specific roles. When we do, if a role is not
// defined for a certain route, it will by default redirect to the 404 route.
interface RouteWithRoles extends RouteBase {
  roles: {
    [key in Role]?: {
      component: any;
      guard?: typeof BaseAuthGuard;
    };
  };
}

// Routes without roles specify that any user role can access this component
interface RouteWithoutRoles extends RouteBase {
  component?: any;
}

type Routes = {
  [key: string]: RouteWithRoles | RouteWithoutRoles;
};

const routes: Routes = {
  root: {
    getLinkPath: () => routes.root.path,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/',
    routes: {
      index: {
        index: true,
        roles: {
          [Role.CONTRACTOR]: {
            component: () => <Navigate replace to={routes.dashboard.path} />,
          },
          [Role.GENERAL_CONTRACTOR]: {
            component: () => <Navigate replace to={routes.contractorProjects.path} />,
          },
          [Role.PLATFORM_MAINTAINER]: {
            component: lazy(() => import('src/views/platformMaintainer/welcome/WelcomeView')),
          },
        },
        getLinkPath: () => routes.root.path,
      },
    },
  },

  dashboard: {
    guard: AuthGuard,
    layout: DashboardLayout,
    getLinkPath: () => routes.dashboard.path,
    path: '/dashboard',
    routes: {
      index: {
        index: true,
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/contractor/dashboard/DashboardView/DashboardView')),
          },
        },
        getLinkPath: () => routes.dashboard.path,
      },
    },
  },

  exports: {
    guard: AuthGuard,
    roles: {
      [Role.CONTRACTOR]: {
        component: Fragment,
      },
    },
    layout: DashboardLayout,
    getLinkPath: () => routes.exports.path,
    path: '/exports',
    routes: {
      timeBookings: {
        guard: authGuardWithPermissions([Permission.TIME_TRACKING_EXPORT_TIMEBOOKINGS]),
        component: lazy(() => import('src/views/contractor/exports/timeBookings/TimeBookingsView')),
        exact: true,
        get path() {
          return 'time-bookings';
        },
        getLinkPath: (params?: { [key: string]: number[] | number }) =>
          `${routes.exports.path}/${routes.exports.routes.timeBookings.path}${stringifyParams(params)}`,
      },
    },
  },

  reports: {
    guard: AuthGuard,
    roles: {
      [Role.CONTRACTOR]: {
        component: Fragment,
      },
    },
    layout: DashboardLayout,
    getLinkPath: () => routes.reports.path,
    path: '/reports',
    routes: {
      index: {
        index: true,
        guard: authGuardWithPermissions([Permission.REPORTS_VIEW_REPORT]),
        component: lazy(() => import('src/views/contractor/reports/ReportsView/ReportsView')),
        getLinkPath: () => routes.reports.path,
      },
      create: {
        guard: authGuardWithPermissions([Permission.REPORTS_ADD_REPORT]),
        component: lazy(() => import('src/views/contractor/reports/ReportsCreateView/ReportsCreateView')),
        exact: true,
        get path() {
          return `create`;
        },
        getLinkPath: () => `${routes.reports.path}/${routes.reports.routes.create.path}`,
      },
      fallback: {
        component: (): any => <Navigate replace to={routes.notFound.path} />,
      },
    },
  },

  login: {
    component: lazy(() => import('src/views/common/auth/LoginView/LoginView')),
    exact: true,
    getLinkPath: () => routes.login.path,
    guard: GuestGuard,
    path: '/login',
  },

  contractorProjects: {
    roles: {
      [Role.CONTRACTOR]: {
        component: Fragment,
      },
    },
    guard: AuthGuard,
    layout: DashboardLayout,
    getLinkPath: () => routes.contractorProjects.path,
    path: '/projects',
    routes: {
      index: {
        index: true,
        component: lazy(
          () => import('src/views/contractor/contractorProject/ContractorProjectsView/ContractorProjectsView'),
        ),
        getLinkPath: (params?: { [key: string]: number }) =>
          `${routes.contractorProjects.path}${stringifyParams(params)}`,
      },

      create: {
        guard: authGuardWithPermissions([Permission.CONTRACTORS_ADD_CONTRACTOR_PROJECT]),
        component: lazy(
          () =>
            import('src/views/contractor/contractorProject/ContractorProjectCreateView/ContractorProjectCreateView'),
        ),
        exact: true,
        get path() {
          return 'create';
        },
        getLinkPath: () => `${routes.contractorProjects.path}/${routes.contractorProjects.routes.create.path}`,
      },

      edit: {
        guard: authGuardWithPermissions([Permission.CONTRACTORS_CHANGE_CONTRACTOR_PROJECT]),
        component: lazy(
          () => import('src/views/contractor/contractorProject/ContractorProjectEditView/ContractorProjectEditView'),
        ),
        exact: true,
        get path() {
          return ':contractorProjectId/edit';
        },
        getLinkPath: (params: { contractorProjectId: number }) => {
          return `${routes.contractorProjects.path}/${params.contractorProjectId}/edit`;
        },
      },

      dayLabor: {
        guard: authGuardWithPermissions([Permission.DAY_LABOR_VIEW_DAYLABOR]),
        component: lazy(() => import('src/views/contractor/contractorProject/DayLaborView/DayLaborView')),
        exact: true,
        getLinkPath: (params: { contractorProjectId: number }) => {
          return `${routes.contractorProjects.path}/${params.contractorProjectId}/day-labor`;
        },
        get path() {
          return ':contractorProjectId/day-labor';
        },
      },

      dayLaborEdit: {
        guard: authGuardWithPermissions([
          Permission.DAY_LABOR_CHANGE_TEAMDAYLABOR,
          Permission.DAY_LABOR_VIEW_TEAMDAYLABOR,
        ]),
        component: lazy(
          () => import('src/views/contractor/contractorProject/DayLaborView/EditTeamDayLabor/EditTeamDayLaborView'),
        ),
        exact: true,
        getLinkPath: (params: { contractorProjectId: number; teamDayLaborId: number }) => {
          return `${routes.contractorProjects.path}/${params.contractorProjectId}/day-labor/edit/${params.teamDayLaborId}`;
        },
        get path() {
          return ':contractorProjectId/day-labor/edit/:teamDayLaborId';
        },
      },

      overview: {
        component: lazy(
          () =>
            import('src/views/shared/contractorProject/ContractorProjectOverviewView/ContractorProjectOverviewView'),
        ),
        exact: true,
        get path() {
          return ':contractorProjectId/overview';
        },
        getLinkPath: (params: { contractorProjectId: number }) => {
          return `${routes.contractorProjects.path}/${params.contractorProjectId}/overview`;
        },
      },

      // Beginning of Project Specification routes
      // These routes are nested in projects because the nesting in the url and breadcrumbs
      // was an acceptance criteria
      //
      // Since the following couple of routes are a bit confusing, here some insights.
      // A specification can, but isn't required to, have task lists that group multiple tasks. In case a specification
      // uses task lists to group a task, a click on a specification redirects the user to a page that shows its task
      // lists. A click on a task list redirects the user to the task lists tasks and a click on a single task
      // redirects the user to that task's detail page. The routes of these pages contain `task lists` to build up a
      // proper breadcrumb. For a specification without task lists, a click on the specification directly lists the
      // tasks and brings the user to the task's detail page. The routes of these two pages do not contain `task lists`.
      // Thus the multiple routes for something that, at first glance, could be considered the same.

      // The detail page of a task that is grouped in a task list
      specificationTaskOverview: {
        component: lazy(() => import('src/views/shared/specification/TaskOverview/TaskOverviewView')),
        exact: true,
        get path() {
          return ':contractorProjectId/specifications/:specificationId/task-lists/:taskListId/tasks/:taskId';
        },
        getLinkPath: (params: {
          contractorProjectId: number;
          specificationId: number;
          taskListId: number;
          taskId: number;
        }) =>
          `${routes.contractorProjects.path}/${params.contractorProjectId}/specifications/${params.specificationId}/task-lists/${params.taskListId}/tasks/${params.taskId}`,
      },
      // The detail page of a task that does not have a task list, just a specification
      specificationWithoutTaskListTaskOverview: {
        component: lazy(() => import('src/views/shared/specification/TaskOverview/TaskOverviewView')),
        exact: true,
        get path() {
          return ':contractorProjectId/specifications/:specificationId/tasks/:taskId';
        },
        getLinkPath: (params: { contractorProjectId: number; specificationId: number; taskId: number }) =>
          `${routes.contractorProjects.path}/${params.contractorProjectId}/specifications/${params.specificationId}/tasks/${params.taskId}`,
      },

      // All task lists of a specification
      specificationTaskLists: {
        component: lazy(() => import('src/views/shared/specification/TaskListsView/TaskListsView')),
        exact: true,
        get path() {
          return ':contractorProjectId/specifications/:specificationId/task-lists';
        },
        getLinkPath: (params: { contractorProjectId: number; specificationId: number }) =>
          `${routes.contractorProjects.path}/${params.contractorProjectId}/specifications/${params.specificationId}/task-lists`,
      },

      // All tasks of a task list
      specificationTaskListTasks: {
        component: lazy(() => import('src/views/shared/specification/TasksView/TasksView')),
        exact: true,
        get path() {
          return ':contractorProjectId/specifications/:specificationId/task-lists/:taskListId/tasks';
        },
        getLinkPath: (params: { contractorProjectId: number; specificationId: number; taskListId: number }) =>
          `${routes.contractorProjects.path}/${params.contractorProjectId}/specifications/${params.specificationId}/task-lists/${params.taskListId}/tasks`,
      },

      // All tasks that belong to a specification that does not have any task lists
      specificationTasks: {
        component: lazy(() => import('src/views/shared/specification/TasksView/TasksView')),
        exact: true,
        get path() {
          return ':contractorProjectId/specifications/:specificationId/tasks';
        },
        getLinkPath: (params: { contractorProjectId: number; specificationId: number }) =>
          `${routes.contractorProjects.path}/${params.contractorProjectId}/specifications/${params.specificationId}/tasks`,
      },

      specificationAdditionalTexts: {
        component: lazy(() => import('src/views/shared/specification/AdditionalTextsView/AdditionalTextsView')),
        exact: true,
        get path() {
          return ':contractorProjectId/specifications/:specificationId/additional-texts';
        },
        getLinkPath: (params: {
          contractorProjectId: number;
          specificationId: number;
          category: AdditionalTextCategory;
        }) =>
          `${routes.contractorProjects.path}/${params.contractorProjectId}/specifications/${params.specificationId}/additional-texts?category=${params.category}`,
      },

      // End of Project Specification routes

      fallback: {
        component: (): any => <Navigate replace to={routes.notFound.path} />,
      },
    },
  },

  constructionProjects: {
    guard: AuthGuard,
    roles: {
      [Role.GENERAL_CONTRACTOR]: {
        component: Fragment,
      },
    },
    layout: DashboardLayout,
    getLinkPath: () => routes.constructionProjects.path,
    path: '/projects',
    routes: {
      index: {
        index: true,
        component: lazy(
          () =>
            import('src/views/generalContractor/constructionProject/ConstructionProjectsView/ConstructionProjectsView'),
        ),
        getLinkPath: () => routes.constructionProjects.path,
      },

      overview: {
        component: lazy(
          () =>
            import(
              'src/views/generalContractor/constructionProject/ConstructionProjectOverviewView/ConstructionProjectOverviewView'
            ),
        ),
        exact: true,
        get path() {
          return ':constructionProjectId/overview';
        },
        getLinkPath: (params: { constructionProjectId: number }) => {
          return `${routes.constructionProjects.path}/${params.constructionProjectId}/overview`;
        },
      },

      // contractor project, e.g. the contractor project of a construction project
      subContractorProjects: {
        get path() {
          return ':constructionProjectId/contractor-projects/:contractorProjectId';
        },
        getLinkPath: (params: { constructionProjectId: number; contractorProjectId: number }) =>
          `${routes.constructionProjects.path}/${params.constructionProjectId}/contractor-projects/${params.contractorProjectId}`,
        routes: {
          index: {
            index: true,
            component: lazy(
              () =>
                import(
                  'src/views/shared/contractorProject/ContractorProjectOverviewView/ContractorProjectOverviewView'
                ),
            ),
            getLinkPath: (params: { constructionProjectId: number; contractorProjectId: number }) =>
              `${routes.constructionProjects.path}/${params.constructionProjectId}/contractor-projects/${params.contractorProjectId}`,
          },

          // Beginning of Project Specification routes
          // These routes are nested in projects because the nesting in the url and breadcrumbs
          // was an acceptance criteria
          //
          // Since the following couple of routes are a bit confusing, here some insights.
          // A specification can, but isn't required to, have task lists that group multiple tasks. In case a specification
          // uses task lists to group a task, a click on a specification redirects the user to a page that shows its task
          // lists. A click on a task list redirects the user to the task lists tasks and a click on a single task
          // redirects the user to that task's detail page. The routes of these pages contain `task lists` to build up a
          // proper breadcrumb. For a specification without task lists, a click on the specification directly lists the
          // tasks and brings the user to the task's detail page. The routes of these two pages do not contain `task lists`.
          // Thus the multiple routes for something that, at first glance, could be considered the same.

          // The detail page of a task that is grouped in a task list
          specificationTaskOverview: {
            component: lazy(() => import('src/views/shared/specification/TaskOverview/TaskOverviewView')),
            exact: true,
            get path() {
              return 'specifications/:specificationId/task-lists/:taskListId/tasks/:taskId';
            },
            getLinkPath: (params: {
              constructionProjectId: number;
              contractorProjectId: number;
              specificationId: number;
              taskListId: number;
              taskId: number;
            }) =>
              `${routes.constructionProjects.routes.subContractorProjects.getLinkPath({
                constructionProjectId: params.constructionProjectId,
                contractorProjectId: params.contractorProjectId,
              })}/specifications/${params.specificationId}/task-lists/${params.taskListId}/tasks/${params.taskId}`,
          },
          // The detail page of a task that does not have a task list, just a specification
          specificationWithoutTaskListTaskOverview: {
            component: lazy(() => import('src/views/shared/specification/TaskOverview/TaskOverviewView')),
            exact: true,
            get path() {
              return 'specifications/:specificationId/tasks/:taskId';
            },
            getLinkPath: (params: {
              constructionProjectId: number;
              contractorProjectId: number;
              specificationId: number;
              taskId: number;
            }) =>
              `${routes.constructionProjects.routes.subContractorProjects.getLinkPath({
                constructionProjectId: params.constructionProjectId,
                contractorProjectId: params.contractorProjectId,
              })}/specifications/${params.specificationId}/tasks/${params.taskId}`,
          },

          // All task lists of a specification
          specificationTaskLists: {
            component: lazy(() => import('src/views/shared/specification/TaskListsView/TaskListsView')),
            exact: true,
            get path() {
              return 'specifications/:specificationId/task-lists';
            },
            getLinkPath: (params: {
              constructionProjectId: number;
              contractorProjectId: number;
              specificationId: number;
            }) =>
              `${routes.constructionProjects.routes.subContractorProjects.getLinkPath({
                constructionProjectId: params.constructionProjectId,
                contractorProjectId: params.contractorProjectId,
              })}/specifications/${params.specificationId}/task-lists`,
          },

          // All tasks of a task list
          specificationTaskListTasks: {
            component: lazy(() => import('src/views/shared/specification/TasksView/TasksView')),
            exact: true,
            get path() {
              return 'specifications/:specificationId/task-lists/:taskListId/tasks';
            },
            getLinkPath: (params: {
              constructionProjectId: number;
              contractorProjectId: number;
              specificationId: number;
              taskListId: number;
            }) =>
              `${routes.constructionProjects.routes.subContractorProjects.getLinkPath({
                constructionProjectId: params.constructionProjectId,
                contractorProjectId: params.contractorProjectId,
              })}/specifications/${params.specificationId}/task-lists/${params.taskListId}/tasks`,
          },

          // All tasks that belong to a specification that does not have any task lists
          specificationTasks: {
            component: lazy(() => import('src/views/shared/specification/TasksView/TasksView')),
            exact: true,
            get path() {
              return 'specifications/:specificationId/tasks';
            },
            getLinkPath: (params: {
              constructionProjectId: number;
              contractorProjectId: number;
              specificationId: number;
            }) =>
              `${routes.constructionProjects.routes.subContractorProjects.getLinkPath({
                constructionProjectId: params.constructionProjectId,
                contractorProjectId: params.contractorProjectId,
              })}/specifications/${params.specificationId}/tasks`,
          },

          specificationAdditionalTexts: {
            component: lazy(() => import('src/views/shared/specification/AdditionalTextsView/AdditionalTextsView')),
            exact: true,
            get path() {
              return 'specifications/:specificationId/additional-texts';
            },
            getLinkPath: (params: {
              constructionProjectId: number;
              contractorProjectId: number;
              specificationId: number;
              category: AdditionalTextCategory;
            }) =>
              `${routes.constructionProjects.routes.subContractorProjects.getLinkPath({
                constructionProjectId: params.constructionProjectId,
                contractorProjectId: params.contractorProjectId,
                category: params.category,
              })}/specifications/${params.specificationId}/additional-texts?category=${params.category}`,
          },
        },
      },

      //
      // End of Project Specification routes

      fallback: {
        component: (): any => <Navigate replace to={routes.notFound.path} />,
      },
    },
  },
  profile: {
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/profile',
    getLinkPath: () => routes.profile.path,
    routes: {
      index: {
        index: true,
        component: lazy(() => import('src/views/shared/Profile')),
        getLinkPath: () => routes.profile.path,
      },
    },
  },

  settings: {
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/settings',
    getLinkPath: () => routes.settings.path,

    routes: {
      index: {
        index: true,
        component: lazy(() => import('src/views/shared/settings/Settings')),
        getLinkPath: () => routes.settings.path,
      },
    },
  },

  legalEntity: {
    guard: authGuardWithPermissions([
      Permission.LEGAL_ENTITIES_CHANGE_LEGAL_ENTITY,
      Permission.LEGAL_ENTITIES_VIEW_LEGAL_ENTITY,
    ]),
    layout: DashboardLayout,
    path: '/legal-entity',
    getLinkPath: () => routes.legalEntity.path,
    routes: {
      index: {
        index: true,
        component: lazy(() => import('src/views/shared/legalEntity/LegalEntityViews/LegalEntityView')),
        getLinkPath: () => routes.legalEntity.path,
      },
    },
  },

  employeeSettings: {
    guard: authGuardWithPermissions(RETRIEVE_UPDATE_CONTRACTOR_CONFIGURATION_PERMISSIONS),
    layout: DashboardLayout,
    path: '/employee-settings',
    getLinkPath: () => routes.employeeSettings.path,
    routes: {
      index: {
        index: true,
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/legalEntity/EmployeeSettingsView/EmployeeSettingsView')),
          },
        },
        getLinkPath: () => routes.employeeSettings.path,
      },
      fallback: {
        component: (): any => <Navigate replace to={routes.notFound.path} />,
      },
    },
  },

  reportSettings: {
    guard: authGuardWithPermissions(RETRIEVE_UPDATE_CONTRACTOR_CONFIGURATION_PERMISSIONS),
    layout: DashboardLayout,
    path: '/report-settings',
    getLinkPath: () => routes.reportSettings.path,
    routes: {
      index: {
        index: true,
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/legalEntity/ReportSettingsView')),
          },
        },
        getLinkPath: () => routes.reportSettings.path,
      },
      fallback: {
        component: (): any => <Navigate replace to={routes.notFound.path} />,
      },
    },
  },

  storage: {
    guard: authGuardWithPermissions([Permission.LEGAL_ENTITIES_VIEW_LEGAL_ENTITY]),
    layout: DashboardLayout,
    path: '/storage',
    getLinkPath: () => routes.storage.path,
    routes: {
      index: {
        index: true,
        component: lazy(() => import('src/views/shared/settings/Storage')),
        getLinkPath: () => routes.storage.path,
      },
    },
  },

  notes: {
    guard: authGuardWithPermissions([Permission.NOTES_VIEW_PROJECTNOTE]),
    layout: DashboardLayout,
    path: '/notes',
    getLinkPath: () => {
      return routes.notes.path;
    },
    roles: {
      [Role.CONTRACTOR]: {
        component: lazy(() => import('src/views/contractor/projectNotes/ProjectNotesView')),
      },
    },
    routes: {
      index: {
        index: true,
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/contractor/projectNotes/ProjectNotesView')),
          },
        },
        getLinkPath: (params?: { [key: string]: number[] | number }) =>
          `${routes.notes.path}${stringifyParams(params)}`,
      },
      create: {
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(
              () => import('src/views/contractor/projectNotes/ProjectNotesCreateView/ProjectNotesCreateView'),
            ),
          },
        },
        exact: true,
        get path() {
          return 'create';
        },
        getLinkPath: () => `${routes.notes.path}/${routes.notes.routes.create.path}`,
      },
      edit: {
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(
              () => import('src/views/contractor/projectNotes/ProjectNotesEditView/ProjectNotesEditView'),
            ),
          },
        },
        exact: true,
        get path() {
          return ':noteId/edit';
        },
        getLinkPath: (params: { notesId: number; next?: string }) => {
          const { notesId, next } = params;
          return `${routes.notes.path}/${notesId}/edit${next ? `?next=${next}` : ''}`;
        },
      },
    },
  },

  documents: {
    guard: AuthGuard,
    layout: DashboardLayout,
    getLinkPath: () => routes.documents.path,
    path: '/documents',
    routes: {
      index: {
        index: true,
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/documents/DocumentsView')),
          },
          [Role.GENERAL_CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/documents/DocumentsView')),
          },
        },
        getLinkPath: (params?: { contractorProjects?: number[] | number; constructionProjects?: number[] | number }) =>
          `${routes.documents.path}${stringifyParams(params)}`,
      },

      create: {
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/documents/DocumentsCreateView/DocumentsCreateView')),
          },
          [Role.GENERAL_CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/documents/DocumentsCreateView/DocumentsCreateView')),
          },
        },
        exact: true,
        get path() {
          return 'create';
        },
        getLinkPath: (params?: { contractorProjects?: number[] | number; constructionProjects?: number[] | number }) =>
          `${routes.documents.path}/${routes.documents.routes.create.path}${stringifyParams(params)}`,
      },

      fallback: {
        component: (): any => <Navigate replace to={routes.notFound.path} />,
      },
    },
  },

  employees: {
    guard: AuthGuard,
    layout: DashboardLayout,
    getLinkPath: () => routes.employees.path,
    path: '/employees',
    routes: {
      index: {
        index: true,
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/employee/EmployeesView/EmployeesView')),
          },
          [Role.GENERAL_CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/employee/EmployeesView/EmployeesView')),
          },
        },
        getLinkPath: () => routes.employees.path,
      },

      create: {
        guard: authGuardWithPermissions([Permission.USERS_ADD_USER]),
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/employee/EmployeeCreateView/EmployeeCreateView')),
          },
          [Role.GENERAL_CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/employee/EmployeeCreateView/EmployeeCreateView')),
          },
        },
        exact: true,
        get path() {
          return 'create';
        },
        getLinkPath: () => `${routes.employees.path}/${routes.employees.routes.create.path}`,
      },

      edit: {
        guard: authGuardWithPermissions([Permission.USERS_CHANGE_USER]),
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/employee/EmployeeEditView/EmployeeEditView')),
          },
          [Role.GENERAL_CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/employee/EmployeeEditView/EmployeeEditView')),
          },
        },
        exact: true,
        get path() {
          return ':employeeId/edit';
        },
        getLinkPath: (params: { employeeId: number }) => `${routes.employees.path}/${params.employeeId}/edit`,
      },

      overview: {
        guard: AuthGuard,
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/contractor/employee/EmployeeOverviewView')),
          },
        },
        path: ':employeeId/overview',
        exact: true,
        getLinkPath: (params: { employeeId: number }) => `${routes.employees.path}/${params.employeeId}/overview`,
      },

      fallback: {
        component: (): any => <Navigate replace to={routes.notFound.path} />,
      },
    },
  },

  customers: {
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/customers',
    getLinkPath: () => routes.customers.path,
    routes: {
      index: {
        index: true,
        guard: authGuardWithPermissions([Permission.CUSTOMERS_VIEW_CUSTOMER]),
        component: lazy(() => import('src/views/shared/customers/CustomersView/CustomersView')),
      },

      create: {
        guard: authGuardWithPermissions([Permission.CUSTOMERS_ADD_CUSTOMER]),
        component: lazy(() => import('src/views/shared/customers/CustomersCreateView/CustomersCreateView')),
        path: 'create',
        getLinkPath: () => `${routes.customers.path}/${routes.customers.routes.create.path}`,
      },

      edit: {
        guard: authGuardWithPermissions([Permission.CUSTOMERS_CHANGE_CUSTOMER]),
        component: lazy(() => import('src/views/shared/customers/CustomersEditView/CustomersEditView')),
        path: ':customerId/edit',
        getLinkPath: (params: { customerId: number }) => `${routes.customers.path}/${params.customerId}/edit`,
      },

      fallback: {
        component: () => <Navigate replace to={routes.notFound.path} />,
      },
    },
  },

  timeBookings: {
    guard: AuthGuard,
    roles: {
      [Role.CONTRACTOR]: {
        component: Fragment,
      },
    },
    layout: DashboardLayout,
    getLinkPath: () => routes.timeBookings.path,
    path: '/timebookings',
    routes: {
      index: {
        index: true,
        guard: authGuardWithPermissions([Permission.TIME_TRACKING_VIEW_TIMEBOOKING]),
        component: lazy(() => import('src/views/contractor/timeBooking/TimeBookingsView/TimeBookingsView')),
        getLinkPath: (params?: { [key: string]: string[] | string }) =>
          `${routes.timeBookings.path}${stringifyParams(params)}`,
      },

      edit: {
        guard: (props: AuthGuardWithCallbackProps) => (
          <AuthGuardWithCallback
            isPermitted={(user: CurrentUser) =>
              [
                Permission.TIME_TRACKING_CHANGE_TIMEBOOKING_CREATED_BY_ME,
                Permission.TIME_TRACKING_CHANGE_TIMEBOOKING,
              ].some((permission) => user.permissions.includes(permission))
            }
            {...props}
          />
        ),
        component: lazy(() => import('src/views/contractor/timeBooking/TimeBookingsEditView/TimeBookingsEditView')),
        exact: true,
        get path() {
          return ':timeBookingId/edit';
        },
        getLinkPath: (params: { timeBookingId: number }) => `${routes.timeBookings.path}/${params.timeBookingId}/edit`,
      },

      create: {
        guard: (props: AuthGuardWithCallbackProps) => (
          <AuthGuardWithCallback
            isPermitted={(user: CurrentUser) =>
              [Permission.TIME_TRACKING_ADD_TEAMTIMEBOOKING, Permission.TIME_TRACKING_ADD_TIMEBOOKING].every(
                (permission) => user.permissions.includes(permission),
              )
            }
            {...props}
          />
        ),
        component: lazy(() => import('src/views/contractor/timeBooking/TimeBookingsCreateView/TimeBookingsCreateView')),
        exact: true,
        get path() {
          return 'create';
        },
        getLinkPath: () => `${routes.timeBookings.path}/${routes.timeBookings.routes.create.path}`,
      },

      fallback: {
        component: (): any => <Navigate replace to={routes.notFound.path} />,
      },
    },
  },

  specifications: {
    guard: AuthGuard,
    roles: {
      [Role.CONTRACTOR]: {
        component: Fragment,
      },
    },
    layout: DashboardLayout,
    getLinkPath: () => routes.specifications.path,
    path: '/specifications',
    routes: {
      index: {
        index: true,
        component: lazy(() => import('src/views/contractor/specification/SpecificationsView/SpecificationsView')),
        getLinkPath: () => routes.specifications.path,
      },

      create: {
        guard: authGuardWithPermissions([Permission.SPECIFICATIONS_IMPORT_GAEB_FILES]),
        component: lazy(
          () => import('src/views/contractor/specification/SpecificationCreateView/SpecificationCreateView'),
        ),
        exact: true,
        get path() {
          return 'create';
        },
        getLinkPath: () => `${routes.specifications.path}/${routes.specifications.routes.create.path}`,
      },

      fallback: {
        component: (): any => <Navigate replace to={routes.notFound.path} />,
      },
    },
  },

  photos: {
    guard: AuthGuard,
    layout: DashboardLayout,
    getLinkPath: () => routes.photos.path,
    path: '/photos',
    routes: {
      index: {
        index: true,
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/photos/PhotosView')),
          },
          [Role.GENERAL_CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/photos/PhotosView')),
          },
        },
        getLinkPath: (params?: { [key: string]: string }) => `${routes.photos.path}${stringifyParams(params)}`,
      },

      create: {
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/photos/PhotosCreateView/PhotosCreateView')),
          },
          [Role.GENERAL_CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/photos/PhotosCreateView/PhotosCreateView')),
          },
        },
        exact: true,
        get path() {
          return 'create';
        },
        getLinkPath: (params?: { tasks?: number; contractorProjects?: number; constructionProjects?: number }) =>
          `${routes.photos.path}/${routes.photos.routes.create.path}${stringifyParams(params)}`,
      },

      edit: {
        guard: authGuardWithPermissions([Permission.FILES_CHANGE_FILES]),
        roles: {
          [Role.CONTRACTOR]: {
            component: lazy(() => import('src/views/shared/photos/PhotosEditView/PhotosEditView')),
          },
        },
        exact: true,
        path: ':photoId/edit',
        getLinkPath: (params: { photoId: number }) => `${routes.photos.path}/${params.photoId}/edit`,
      },

      fallback: {
        component: (): any => <Navigate replace to={routes.notFound.path} />,
      },
    },
  },

  notFound: {
    component: lazy(() => import('src/views/common/errors/NotFoundView/NotFoundView')),
    exact: true,
    getLinkPath: () => routes.notFound.path,
    path: '/404',
  },

  fallback: {
    component: (): any => <Navigate replace to={routes.notFound.path} />,
  },
};

const getRouteComponents = (routes: Routes, user: CurrentUser): JSX.Element[] => {
  const entries = [];

  Object.entries(routes).forEach(([routeName, route]) => {
    const Guard = route.guard || Fragment;
    let RoleSpecificGuard: typeof BaseAuthGuard | typeof Fragment = Fragment;
    const Layout = route.layout || Fragment;

    const routeWithRoles = route as RouteWithRoles;
    const routeWithoutRoles = route as RouteWithoutRoles;

    let addRoute = true;
    let Component: any;
    // Routes are not even registered, if the user role does not match, thus automatically leading to the 404 page.
    if (user && routeWithRoles.roles) {
      if (user.legalEntity.role in routeWithRoles.roles) {
        Component = routeWithRoles.roles[user.legalEntity.role].component;
        RoleSpecificGuard = routeWithRoles.roles[user.legalEntity.role].guard || RoleSpecificGuard;
      } else {
        addRoute = false;
      }
    } else {
      Component = routeWithoutRoles.component || Fragment;
    }

    let routeProps = {};
    if (route.index) {
      routeProps = { index: true };
    } else {
      routeProps = { path: route.path === undefined ? '*' : route.exact ? route.path : `${route.path}/*` };
    }

    if (addRoute) {
      entries.push(
        <Route
          {...routeProps}
          key={routeName}
          element={
            <Guard>
              <RoleSpecificGuard>
                <Layout>{route.routes ? renderRoutes(route.routes, user) : <Component />}</Layout>
              </RoleSpecificGuard>
            </Guard>
          }
        />,
      );
    }
  });
  return entries;
};

export const renderRoutes = (routes: Routes = {}, user: CurrentUser): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <RouterRoutes>{getRouteComponents(routes, user)}</RouterRoutes>
  </Suspense>
);

/**
 * Returns the link to a project, either a contractor or construction project depending on the role provided.
 * @param forRole The role of the user.
 * @param projectId  The id of the project.
 * @returns
 */
export const getProjectDetailLink = (forRole: Role, projectId: number) => {
  if (forRole === Role.CONTRACTOR) {
    return routes.contractorProjects.routes.overview.getLinkPath({
      contractorProjectId: projectId,
    });
  } else if (forRole === Role.GENERAL_CONTRACTOR) {
    return routes.constructionProjects.routes.overview.getLinkPath({
      constructionProjectId: projectId,
    });
  }
};

/**
 * Returns the link to a specification detail page which is, depending on the structure of the specification, a page
 * that lists all the tasks of the specification (no hierarchy / depth 0) or a page with all the specification's task
 * lists.
 *
 * If a constructionProjectId is provided, a link for the general contractor is generated, otherwise one for the
 * contractor.
 *
 * @param params parameters which determine the kind of link that is generated. Mandatory parameters are the
 *  contractorProjectId and specificationId. If a constructionProjectId parameter is provided, an URL for a general
 *  contractor is returned that links to the sub-contractor project's specification. Otherwise the direct link to the
 *  contractor project's specification is returned.
 * @param depth the depth of the specification. Determines whether to link to the task or task list page
 * @returns the detail link of a specification
 */
export const getSpecificationDetailLink = (
  params: {
    contractorProjectId: number;
    specificationId: number;
    constructionProjectId?: number;
  },
  depth: number,
) => {
  let specificationTasksOnlyRoute;
  let specificationTaskListsRoute;
  if (params.constructionProjectId) {
    specificationTasksOnlyRoute = routes.constructionProjects.routes.subContractorProjects.routes.specificationTasks;
    specificationTaskListsRoute =
      routes.constructionProjects.routes.subContractorProjects.routes.specificationTaskLists;
  } else {
    specificationTasksOnlyRoute = routes.contractorProjects.routes.specificationTasks;
    specificationTaskListsRoute = routes.contractorProjects.routes.specificationTaskLists;
  }

  if (depth === 0) {
    // If it is a specification without task lists (depth 0) route to tasks
    return specificationTasksOnlyRoute.getLinkPath(params);
  } else {
    // Else route to task lists
    return specificationTaskListsRoute.getLinkPath(params);
  }
};

/**
 * Returns the link to a task's detail page. There can be two kinds of links generated for a task. The first one
 * contains the task list of a task, which is used to build a proper breadcrumb for the task. If a task does not have
 * a task list another link is returned and no task list appears in the breadcrumb.
 *
 * If a constructionProjectId is provided, a link for the general contractor is generated, otherwise one for the
 * contractor.
 *
 * @param params parameters which determine the kind of link that is generated. Mandatory parameters are the
 *  contractorProjectId, specificationId and taskId. If a constructionProjectId parameter is provided, an URL for a
 *   general contractor is returned that links to the sub-contractor project's task detail page. Otherwise the direct
 *   link to the contractor project's task detail page is. If a taskListId is provided, the URL will be different.
 * @returns the detail link for a task
 */
export const getTaskDetailLink = (params: {
  constructionProjectId?: number | string;
  contractorProjectId: number | string;
  specificationId: number | string;
  taskListId?: number | string;
  taskId: number;
}) => {
  let taskOverviewRoute;
  let specWithoutListsTaskOverviewRoute;
  if (params.constructionProjectId) {
    taskOverviewRoute = routes.constructionProjects.routes.subContractorProjects.routes.specificationTaskOverview;
    specWithoutListsTaskOverviewRoute =
      routes.constructionProjects.routes.subContractorProjects.routes.specificationWithoutTaskListTaskOverview;
  } else {
    taskOverviewRoute = routes.contractorProjects.routes.specificationTaskOverview;
    specWithoutListsTaskOverviewRoute = routes.contractorProjects.routes.specificationWithoutTaskListTaskOverview;
  }

  if (params.taskListId) {
    return taskOverviewRoute.getLinkPath(params);
  } else {
    return specWithoutListsTaskOverviewRoute.getLinkPath(params);
  }
};

/**
 * Returns the link to a specifications's task list, which lists all the tasks belonging to the specified task list.
 *
 * If a constructionProjectId is provided, a link for the general contractor is generated, otherwise one for the
 * contractor.
 *
 * @param params parameters which determine the kind of link that is generated. Mandatory parameters are the
 *  contractorProjectId, specificationId and taskListId. If a constructionProjectId parameter is provided, an URL for a
 *  general contractor is returned that links to the sub-contractor project's task list page. Otherwise the direct
 *  link to the contractor project's task list page is.
 * @returns the detail link to a list of tasks of a task list
 */
export const getSpecificationTaskListTasksLink = (params: {
  constructionProjectId?: number | string;
  contractorProjectId: number | string;
  specificationId: number | string;
  taskListId: number | string;
}) => {
  if (params.constructionProjectId) {
    return routes.constructionProjects.routes.subContractorProjects.routes.specificationTaskListTasks.getLinkPath(
      params,
    );
  } else {
    return routes.contractorProjects.routes.specificationTaskListTasks.getLinkPath(params);
  }
};

/**
 * Returns the link to additional-texts view, which lists all the additional texts belonging to a specification.
 *
 * If constructionProjectId is provided, a link for the general contractor is generated, otherwise one for the
 * contractor.
 *
 * @param params parameters which determine the link to be generated. Mandatory parameters are contractorProjectId,
 * specificationId and category. If constructionProjectId parameter is provided, a URL for general contractor is
 * returned that links to the sub-contractor projects's additional texts page. Otherwise the direct link to contractor
 * project's additional text page is returned.
 * @returns the link to additional texts view
 */
export const getSpecificationAdditionalTextsLink = (params: {
  constructionProjectId?: number | string;
  contractorProjectId: number | string;
  specificationId: number | string;
  category: AdditionalTextCategory;
}) => {
  if (params.constructionProjectId) {
    return routes.constructionProjects.routes.subContractorProjects.routes.specificationAdditionalTexts.getLinkPath(
      params,
    );
  } else {
    return routes.contractorProjects.routes.specificationAdditionalTexts.getLinkPath(params);
  }
};

export default routes;
