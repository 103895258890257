import i18n from 'i18next';

import { Language, MapChoice } from 'src/types/settings';

import { enumHasKey } from './typescript';

const LANGUAGE_KEY = 'language';
const MAP_SETTINGS_KEY = '/settings_mapSettings';

/**
 * Returns first or only fallback language as set in i18n config.
 */
export const fallbackLanguage = (): string =>
  Array.isArray(i18n.options.fallbackLng) ? i18n.options.fallbackLng[0] : i18n.options.fallbackLng;

/**
 * Gets user language code stored in localstorage and if nothing is found in
 * localstorage it returns language set in browser and if there is no language found
 * from browser by any chance then it returns i18n English as fallback language.
 */
export const getLanguageCodeFromStorage = (): string => {
  const storedLang = localStorage.getItem(LANGUAGE_KEY);

  if (storedLang && enumHasKey(Language, storedLang)) {
    return storedLang;
  }

  // Get browser's language and split language code by "-" and returns first part.
  // (e.g, "en-GB" and "en-US" becomes "en")
  const baseLang = navigator.language?.split('-')[0] || fallbackLanguage();
  return baseLang in Language ? baseLang : fallbackLanguage();
};

/**
 * Set User language choice in localstorage.
 */
export const setUserLanguageChoiceInStorage = (languageCode: string): void => {
  localStorage.setItem(LANGUAGE_KEY, languageCode);
};

/**
 * Gets user external map preference from localstorage and if nothing
 * or garbage value is found it returns OpenStreetMap as a default choice.
 */
export const getUserMapChoiceFromLocalStorage = (): MapChoice => {
  const choice = Number(localStorage.getItem(MAP_SETTINGS_KEY));
  if (Object.values(MapChoice).includes(choice)) {
    return choice;
  }
  return MapChoice.OPEN_STREET_MAP;
};

/**
 * Sets user choice for external map service in localstorage
 */
export const setUserMapChoiceInLocalStorage = ({ mapSelection }: { mapSelection: MapChoice }): void => {
  localStorage.setItem(MAP_SETTINGS_KEY, mapSelection.toString());
};
