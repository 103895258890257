import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language as LanguageIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';

import TestIDs from 'src/TestIDs.json';
import { Language } from 'src/types/settings';
import { fallbackLanguage, getLanguageCodeFromStorage, setUserLanguageChoiceInStorage } from 'src/utils/settings';
import { enumHasKey } from 'src/utils/typescript';

const LanguageMenuTestIDs = TestIDs.views.shared.language;

/**
 * Handles language change and persistence across sessions.
 */
const LanguageMenu: FC = () => {
  const { t, i18n } = useTranslation();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [choice, setChoice] = useState<string>(getLanguageCodeFromStorage());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const changeWebLanguage = (code: string) => i18n.changeLanguage(code);

  useEffect(() => {
    changeWebLanguage(getLanguageCodeFromStorage());
  }, []);

  const handleLanguageChange = (selectedCode: string): void => {
    const validCode = enumHasKey(Language, selectedCode) ? selectedCode : fallbackLanguage();
    setChoice(validCode);
    setUserLanguageChoiceInStorage(validCode);
    changeWebLanguage(validCode);
  };

  return (
    <>
      <Tooltip title={t('Topbar.languageMenu')} arrow>
        <IconButton
          color="primary"
          ref={ref}
          onClick={handleOpen}
          size="large"
          data-test-id={LanguageMenuTestIDs.menuIconButton}
        >
          <LanguageIcon />
        </IconButton>
      </Tooltip>
      <Menu
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isOpen}
        keepMounted
        anchorEl={ref.current}
        data-test-id={LanguageMenuTestIDs.languageMenu}
      >
        {Object.entries(Language).map(([code, language]) => (
          <MenuItem key={code} selected={choice === code} onClick={() => handleLanguageChange(code)}>
            {language}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageMenu;
