export function enumKeys<E>(e: E): (keyof E)[] {
  return Object.keys(e) as (keyof E)[];
}

/**
 * This helper function is used to return only actual keys of an enum with numeric values. It is handy when an Enum
 * has numeric values instead of `string`.
 *
 * If an Enum has numeric values, `Object.values` converts them into `string` and returns them as keys too. This
 * function filters them out and returns only real keys of an enum.
 */
export function numericEnumKeys<E>(e: E): (keyof E)[] {
  return enumKeys(e).filter((k) => isNaN(Number(k)));
}

/**
 * Checks if a given key exists in the specified enum.
 *
 * This helper function utilizes `numericEnumKeys` and `enumKeys` to get only actual keys of an enum.
 *
 * This function is designed to avoid an issue where using the `in` operator or direct
 * property access on an enum can return `true` for built-in object properties like `toString`,
 */
export function enumHasKey<E>(e: E, key: string): boolean {
  return numericEnumKeys(e).includes(key as keyof E);
}
